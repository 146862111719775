@import "src/styles/resolutions";

.info {
    max-width: 800px;

    :global {
        .ant-modal-content {
            border-radius: 10px;
        }

        .ant-modal-confirm-content {
            font-size: 18px;
            border-radius: 10px;
        }

        .ant-modal-confirm-btns {

            float: unset;
            text-align: center;
            margin-top: 50px;

            > button {
                border: unset;
                border-radius: 36px;
                width: 100px;
                height: 50px;
            }
        }
    }
}

@media @mobile, @tablet {

    .question {
        height: 100%;

        > div {
            height: 100%;

            .content {
                height: 100%;
                display: flex;
                flex-direction: column-reverse;

                > .left {
                    height: 60%;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;

                    .layout {

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 100%;
                        height: 100%;

                        .header {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            > div {
                                font-weight: 500;

                                button {
                                    padding: 0;

                                    span {
                                        font-weight: 500;
                                    }
                                }
                            }
                        }

                        .category {
                            font-size: 26px;
                            font-weight: 400;
                            margin: 20px 0;

                            > span {
                                font-weight: 600;
                            }
                        }

                        .text {
                            font-size: 20px;
                            font-weight: 400;
                        }

                        .answers {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;
                            height: 100px;

                            .buttons {

                                display: flex;
                                justify-content: space-between;
                                width: 100%;

                                .roundButton {
                                    width: 16px;
                                    height: 16px;
                                    mask: url("./ellipse.svg") no-repeat center;
                                    -webkit-mask: url("./ellipse.svg") no-repeat center;
                                    mask-size: 16px;
                                    -webkit-mask-size: 16px;
                                    border: unset;
                                    cursor: pointer;
                                    transition: 200ms all;
                                }

                                .roundButton:hover {
                                    transform: scale(2);
                                }

                                :global {
                                    .active {
                                        transform: scale(2);
                                    }
                                }
                            }

                            .labels {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;

                                > div {
                                    font-weight: 500;
                                }
                            }
                        }

                        .navigation {
                            display: flex;
                            justify-content: space-between;

                            .prev {
                                padding: 0;
                            }

                            .next {
                                span {
                                    padding: 0 10px;
                                }
                            }

                            button span {
                                font-weight: 500;
                            }
                        }
                    }
                }

                > .right {
                    background-color: #F5F8FB;
                    width: 100%;
                    height: 260px;
                    position: relative;
                    margin: 0;

                    > .image, .currentImage {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        -webkit-transition: all .25s ease-in-out;
                        transition: all .25s ease-in-out;

                        &.visible {
                            opacity: 1;
                        }

                        &.hidden {
                            opacity: 0;
                        }
                    }

                    .exit {
                        position: fixed;
                        right: 15px;
                        top: 15px;

                        > button {
                            width: 80px;
                            height: 40px;
                            border: unset;
                            border-radius: 36px;
                            background-color: #FFB12F;

                            a {
                                color: #fff;
                                font-size: 18px;
                                font-weight: 600;
                            }

                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }
    }

}

@media @desktop {

    .question {
        height: 100%;

        :global {
            .ant-spin-nested-loading > div > .ant-spin,
            .ant-spin-container, .ant-spin-container > div {
                max-height: none;
                height: 100%;
            }
        }

        > div {
            height: 100%;

            .content {
                height: 100%;
                display: flex;

                > .left {
                    padding: 50px;
                    height: 100%;
                    width: 50%;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .layout {

                        position: relative;
                        width: 100%;
                        height: 100%;

                        .top {

                            .header {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                > div {
                                    font-size: 16px;
                                    font-weight: 500;

                                    button {
                                        padding: 0;

                                        span {
                                            font-size: 16px;
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }

                            .category {
                                font-size: 30px;
                                font-weight: 400;
                                margin: 20px 0;

                                > span {
                                    font-weight: 600;
                                }
                            }

                            .text {
                                font-size: 20px;
                                font-weight: 400;
                                height: 100px;
                            }

                        }

                        .answers {
                            position: absolute;
                            top: 50%;
                            width: 100%;
                            height: 100px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .buttons {

                                display: flex;
                                justify-content: space-between;
                                width: 100%;

                                .roundButton {
                                    width: 40px;
                                    height: 40px;
                                    border: 3px solid #FFF;
                                    border-radius: 100%;
                                    -moz-border-radius: 100%;
                                    -webkit-border-radius: 100%;
                                    background: #000;
                                    cursor: pointer;
                                    transition: 200ms all;
                                }

                                :global {
                                    .active {
                                        transform: scale(2);
                                    }
                                }
                            }

                            .labels {
                                display: flex;
                                justify-content: space-between;

                                > div {
                                    font-size: 16px;
                                    font-weight: 500;
                                }
                            }

                        }

                        .navigation {
                            position: absolute;
                            bottom: 0;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;

                            .prev {
                                padding: 0;
                            }

                            .next {
                                span {
                                    padding: 0 10px;
                                }
                            }

                            button {
                                height: 50px;

                                span {
                                    font-size: 16px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }

                > .right {
                    background-color: #F5F8FB;
                    width: 50%;
                    height: 100%;
                    position: relative;

                    > .image, .currentImage {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        -webkit-transition: all .5s ease-in-out;
                        transition: all .5s ease-in-out;

                        &.visible {
                            opacity: 1;
                        }

                        &.hidden {
                            opacity: 0;
                        }
                    }

                    .exit {
                        position: fixed;
                        right: 35px;
                        top: 35px;

                        > button {
                            width: 100px;
                            height: 50px;
                            border: unset;
                            border-radius: 36px;
                            background-color: #FFB12F;

                            a {
                                color: #fff;
                                font-size: 18px;
                                font-weight: 600;
                            }

                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }
    }
}