@import "./src/styles/resolutions";

@media @mobile {
    @import "./ExtraSummaryMobile.module";
}

@media @tablet {
    @import "./ExtraSummaryTablet.module";
}

@media @desktop {
    @import "./ExtraSummaryDesktop.module";
}