.cls-1 {
    fill: none;
}

.cls-2 {
    fill: #757575;
}

.cls-3 {
    fill: #fff;
}

.cls-4 {
    fill: #9db5c3;
}

.cls-5 {
    fill: #eff1f2;
}

.cls-6 {
    fill: #d1dce3;
}

.cls-7 {
    fill: #b7c6cf;
}

.cls-8 {
    fill: #ec691a;
}

.cls-9 {
    fill: #003c5a;
}

.cls-10 {
    fill: #2b352b;
}

.cls-11, .cls-22 {
    fill: #86c9e9;
}

.cls-12 {
    fill: #d29e88;
}

.cls-13, .cls-24 {
    fill: #fab519;
}

.cls-14 {
    fill: #8f522f;
}

.cls-15, .cls-23 {
    fill: #d570aa;
}

.cls-16 {
    fill: #2c352b;
}

.cls-17 > tspan {
    text-align: center;
    width: 100px;
    fill: #0f3f56;
    font-size: 34px;
    font-family: Poppins-Medium, Poppins, serif;
    font-weight: 600;
}

.cls-18 {
    fill: #e3e9ec;
}

.cls-19 {
    clip-path: url(#clip-path);
}

.cls-20 {
    clip-path: url(#clip-path-2);
}

.cls-21 {
    fill: url(#pattern);
}

.cls-22, .cls-23, .cls-24 {
    font-size: 19.162px;
    font-family: Poppins-SemiBold, Poppins, serif;
    font-weight: 600;
}