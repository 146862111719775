@import "./src/styles/resolutions";

@media @mobile {
    @import "./DashboardMobile.module";
}

@media @tablet {
    @import "./DashboardTablet.module";
}

@media @desktop {
    @import "./DashboardDesktop.module";
}