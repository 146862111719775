.dashboard {
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;

    .layout {

        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        > .left {

            z-index: 1;
            margin: 0 50px;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;

            > .content {

                width: 100%;
                margin-top: auto;

                h1 {
                    font-size: 60px;
                    line-height: 60px;
                    font-weight: 600;
                    margin: 25px 0;
                }

                h2 {
                    font-size: 24px;
                    margin: 25px 0;
                }

                .action {

                    margin: 25px 0;

                    button {
                        color: #fff;
                        background-color: #D672A9;
                        border-radius: 36px;
                        border: unset;
                        width: 300px;
                        height: 70px;

                        > span {
                            font-size: 24px;
                            font-weight: 500;
                        }
                    }
                }
            }

            > .logo {
                margin-top: auto;
                margin-bottom: 50px;
                width: 300px;
            }
        }

        .image {
            padding: 50px;
            background-image: url('../../dashboard/assets/dashboard-background.svg');
            background-repeat: no-repeat;
            background-position: left;
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > img {
                max-width: 35vw;
                max-height: 35vh;
                width: 100%;
                height: auto;
            }
        }

        .exit {
            position: fixed;
            right: 35px;
            top: 35px;

            > button {
                width: 100px;
                height: 50px;
                border: unset;
                border-radius: 36px;
                background-color: #fff;

                a {
                    color: #000;
                    font-size: 18px;
                    font-weight: 600;
                }

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}