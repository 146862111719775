@import "src/styles/resolutions";

@media @mobile, @tablet {

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 400px;
    }

}

@media @desktop {

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100%;
    }

}

.ant-spin-dot {
    width: 40px;
    height: 40px;
}

.ant-spin-dot-item {
    width: 20px;
    height: 20px;
}

.ant-spin-nested-loading > div > .ant-spin {
    max-height: none;
    min-height: 100%;
}
