.root {

    width: 100%;
    flex: 1 0 auto;

    > .header {
        display: flex;
        justify-content: space-between;
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 10px;

        > .title {
            font-size: 32px;
            font-weight: 500;
        }

        > .index {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .options {
        width: 100%;
        height: calc(100% - 75px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        :global {
            .ant-input-number {
                display: block;
                width: 100%;
                height: 50px;
                background-color: rgba(214, 114, 169, 0.05);
                border: 2px solid #D672A9;
                border-radius: 36px;

                input {
                    display: flex;
                    text-align: center;
                    margin-top: 9px;
                    font-size: 18px;
                    font-weight: 500;
                }

                input::placeholder {
                    color: #D672A9;
                    opacity: 0.5;
                }

                .ant-input-number-handler-wrap {
                    width: 26px;
                    right: 2px;

                    border-top-right-radius: 36px;
                    border-bottom-right-radius: 36px;

                    > span:first-of-type {
                        border-top-right-radius: 36px;
                    }
                    > span:last-of-type {
                        border-bottom-right-radius: 36px;
                    }

                    .anticon {
                        margin-right: 3px;
                    }
                }
            }
        }

    }
}