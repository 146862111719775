@import "src/styles/resolutions";

@media @mobile, @tablet {

    .summary {

        background-color: #fff;
        padding-bottom: 30px;
        min-height: 100%;

        .layout {
            display: flex;
            flex-direction: column;

            .header {

                padding: 0 5% 20px;
                margin: 0;

                h1 {
                    font-size: 36px;
                    font-weight: 600;
                    margin: 10px 0;
                }

                .subheader {
                    font-size: 18px;
                    font-weight: normal;
                    margin: 20px 0 0;
                }

            }

            .actions {
                display: flex;
                flex-direction: column;
                padding: 0 5%;

                .finished {
                    border: 1px #D672A9 solid;
                    color: #D672A9;
                    height: 49px;
                    width: 100%;
                    margin: 10px 0;

                    > span {
                        font-size: 16px;
                        font-weight: 500;
                        padding: 0 10px;
                    }
                }

                .finished:hover {
                    opacity: 0.5;
                }

                .learnMore {

                    width: 100%;
                    height: 49px;
                    border: 1px solid #70CDE9;
                    background-color: rgba(112, 205, 233, 0.1);
                    color: #70CDE9;
                    font-size: 16px;

                    &:hover {
                        color: #fff;
                        background-color: #70CDE9;
                    }

                    > span {
                        font-weight: 500;
                    }
                }

            }

            .cup {

                background-image: url("../assets/summary-mobile-background.svg");
                background-position: center bottom;
                background-size: cover;
                background-repeat: no-repeat;
                padding: 0 20px 50px;

                > div {

                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .legend {
                        font-size: 24px;
                        line-height: 35px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        > .health {
                            color: #70CDE9;
                        }

                        > .happiness {
                            color: #D672A9;
                        }

                        > .success {
                            color: #FFB12F;
                        }

                        > .health, .happiness, .success {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

@media @desktop {
    .summary {
        background-color: #fff;
        min-height: 100%;
        display: flex;

        .layout {
            width: 100%;
            min-height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                .content {
                    max-width: 664px;
                    width: 100%;
                    padding: 50px 0 50px 50px;

                    > .first {
                        height: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-end;

                        .header {

                            h1 {
                                font-size: calc(30px + 2vw);
                                line-height: normal;
                                font-weight: 600;
                                margin: 0;
                                white-space: nowrap;
                            }

                            .subheader {
                                font-size: calc(10px + 1.5vw);
                                line-height: normal;
                                font-weight: normal;
                                margin: 30px 0;
                            }

                        }

                        .actions {
                            display: flex;
                            flex-direction: column;
                            width: 400px;

                            .feedback {
                                background-color: #D672A9;
                                border: unset;
                                border-radius: 36px;
                                color: #fff;
                                height: 49px;
                                width: 100%;

                                > span {
                                    font-size: 16px;
                                    font-weight: 500;
                                    padding: 0 10px;
                                }

                                &:hover {
                                    color: #fff;
                                    background-color: #D672A9;
                                }

                                &:active, &:focus {
                                    color: #fff;
                                    background-color: #D672A9;
                                }
                            }

                            .finished {
                                margin-top: 20px;
                                margin-right: 10px;
                                background-color: #fff;
                                border: 1px #D672A9 solid;
                                border-radius: 36px;
                                color: #D672A9;
                                height: 49px;
                                width: 100%;

                                > span {
                                    font-size: 16px;
                                    font-weight: 500;
                                    padding: 0 10px;
                                }

                                &:hover {
                                    opacity: 0.5;
                                }
                            }

                            .learnMore {

                                width: 100%;
                                margin-top: 20px;
                                height: 49px;
                                border: 1px solid #70CDE9;
                                border-radius: 36px;
                                background-color: rgba(112, 205, 233, 0.1);
                                color: #70CDE9;
                                font-size: 16px;

                                &:hover {
                                    color: #fff;
                                    background-color: #70CDE9;
                                }

                                > span {
                                    font-weight: 500;
                                }
                            }

                            > div:nth-of-type(1) {
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }

            .right {
                background-image: url('../assets/summary-background.svg');
                background-position: left;
                background-size: auto 1920px;
                width: 55%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                .cup > div {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .legend {
                        font-size: 42px;
                        line-height: 54px;
                        margin: 10px 0;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        > .health {
                            color: #70CDE9;
                        }

                        > .happiness {
                            color: #D672A9;
                        }

                        > .success {
                            color: #FFB12F;
                        }

                        > .health, .happiness, .success {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}