html {
    min-width: 320px;
    scroll-behavior: smooth;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
}

body * {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    min-height: 100%;
    height: 100%;
}

.app_3g35f {
  display: flex;
  display: -webkit-flexbox;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: #F6F8FB;
  width: 100%;
  height: 100%;
}

.bingo_2O-5f {
  height: 100vh;
  display: flex;
  background-image: url(/static/media/background.a17e4e1f.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.bingo_2O-5f > .left_8GXh3 {
  height: 100%;
  width: 60%;
  color: #fff;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
}
.bingo_2O-5f > .left_8GXh3 > .header_2zUBP {
  padding: 30px 0 40px;
  font-size: 22px;
}
.bingo_2O-5f > .left_8GXh3 > .header_2zUBP > div > div {
  font-weight: 400;
}
.bingo_2O-5f > .left_8GXh3 > .header_2zUBP > .bravo_3M_Yp {
  font-size: 60px;
  font-weight: 500;
}
.bingo_2O-5f > .left_8GXh3 > .scores_2elvb {
  position: relative;
  display: grid;
  grid-template-columns: auto -webkit-max-content;
  grid-template-columns: auto max-content;
}
.bingo_2O-5f > .left_8GXh3 > .scores_2elvb .scoresValues_2UQPa {
  margin: 0 0 25px;
  max-height: 100%;
}
.bingo_2O-5f > .left_8GXh3 > .scores_2elvb .scoresValues_2UQPa > svg {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.bingo_2O-5f > .left_8GXh3 > .scores_2elvb > .overall_324Kh {
  position: relative;
  display: grid;
  grid-template-columns: auto -webkit-max-content;
  grid-template-columns: auto max-content;
  grid-template-rows: minmax(100%, 300px);
}
.bingo_2O-5f > .left_8GXh3 > .scores_2elvb > .overall_324Kh > div {
  margin: 0 0 25px;
  max-height: 100%;
}
.bingo_2O-5f > .left_8GXh3 > .scores_2elvb > .overall_324Kh > div > svg {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.bingo_2O-5f > .left_8GXh3 .finished_1lmHf {
  margin-top: auto;
  margin-right: 10px;
  background-color: #D672A9;
  border: unset;
  color: #fff;
  height: 50px;
  width: 300px;
}
.bingo_2O-5f > .left_8GXh3 .finished_1lmHf a {
  font-size: 18px;
  font-weight: 500;
  padding: 0 10px;
}
.bingo_2O-5f > .left_8GXh3 .finished_1lmHf:hover {
  opacity: 0.5;
}
.bingo_2O-5f > .left_8GXh3 .logo_2yX83 {
  margin-top: 35px;
  margin-bottom: 50px;
  width: 150px;
}
.bingo_2O-5f > .right_3vaND {
  width: 40%;
  height: 100%;
  padding: 50px;
}
.bingo_2O-5f > .right_3vaND > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bingo_2O-5f > .right_3vaND > div > img {
  width: auto;
  height: 100%;
}

.cls-1 {
    fill: none;
}

.cls-2 {
    fill: #757575;
}

.cls-3 {
    fill: #fff;
}

.cls-4 {
    fill: #9db5c3;
}

.cls-5 {
    fill: #eff1f2;
}

.cls-6 {
    fill: #d1dce3;
}

.cls-7 {
    fill: #b7c6cf;
}

.cls-8 {
    fill: #ec691a;
}

.cls-9 {
    fill: #003c5a;
}

.cls-10 {
    fill: #2b352b;
}

.cls-11, .cls-22 {
    fill: #86c9e9;
}

.cls-12 {
    fill: #d29e88;
}

.cls-13, .cls-24 {
    fill: #fab519;
}

.cls-14 {
    fill: #8f522f;
}

.cls-15, .cls-23 {
    fill: #d570aa;
}

.cls-16 {
    fill: #2c352b;
}

.cls-17 > tspan {
    text-align: center;
    width: 100px;
    fill: #0f3f56;
    font-size: 34px;
    font-family: Poppins-Medium, Poppins, serif;
    font-weight: 600;
}

.cls-18 {
    fill: #e3e9ec;
}

.cls-19 {
    -webkit-clip-path: url(#clip-path);
            clip-path: url(#clip-path);
}

.cls-20 {
    -webkit-clip-path: url(#clip-path-2);
            clip-path: url(#clip-path-2);
}

.cls-21 {
    fill: url(#pattern);
}

.cls-22, .cls-23, .cls-24 {
    font-size: 19.162px;
    font-family: Poppins-SemiBold, Poppins, serif;
    font-weight: 600;
}
.cls-1, .cls-3, .cls-4 {
    fill: #fff;
}

.cls-2 {
    color: #0f3f56;
    fill: #0f3f56;
    width: 100%;
    height: 100%;
    font-size: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cls-2 > tspan, .cls-4 > tspan {
    font-family: Poppins-SemiBold, Poppins, serif;
    font-weight: 600;
}

.cls-3 {
    font-size: 14.4px;
    font-family: Poppins-Regular, Poppins, serif;
}

.cls-4 {
    text-align: center;
    font-size: 24px;
}
@media only screen and (max-width: 719px) {
  .dashboard_1JQEF {
    background-image: url(/static/media/dashboard-mobile-background.fec78743.svg);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    background-color: #fff;
    display: flex;
    display: -webkit-flexbox;
    justify-content: center;
    min-height: 100%;
  }
  .dashboard_1JQEF .layout_3KVWE {
    width: 100%;
    display: flex;
    display: -webkit-flexbox;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT {
    z-index: 1;
    padding: 25px;
    width: 100%;
    display: flex;
    display: -webkit-flexbox;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph {
    width: 100%;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .userName_1G0g6 > div {
    color: #0C3E57;
    font-size: 50px;
    font-weight: 600;
    line-height: 51px;
    overflow-wrap: break-word;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H > span {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .progress_14Ci0 {
    margin-bottom: 15px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .progress_14Ci0 > div {
    width: 100px !important;
    height: 100px !important;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .ant-progress-status-success .ant-progress-circle-path,
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .ant-progress-status-success .ant-progress-text {
    color: #29db73;
    stroke: #29db73 !important;
    font-size: 40px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph h3 {
    font-size: 14px;
    font-weight: normal;
    line-height: 23px;
    color: #0C3E57;
    margin-bottom: 30px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .action_3_4_X {
    display: flex;
    justify-content: center;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .action_3_4_X button {
    color: #fff;
    background-color: #D672A9;
    border: unset;
    width: 100%;
    height: 40px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .action_3_4_X button > span {
    font-size: 14px;
    font-weight: 500;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT .logo_29Q59 {
    margin: 50px;
    width: 300px;
  }
  .dashboard_1JQEF .layout_3KVWE .image_2uunV {
    width: 100%;
    display: flex;
    display: -webkit-flexbox;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }
  .dashboard_1JQEF .layout_3KVWE .image_2uunV > img {
    max-width: 45vw;
    max-height: 250px;
    width: 100%;
    height: auto;
  }
  .info_1BZcB .ant-modal-content {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.4);
  }
  .info_1BZcB .ant-modal-confirm-title {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  .info_1BZcB .ant-modal-confirm-content {
    font-size: 14px;
    text-align: justify;
  }
  .info_1BZcB .ant-modal-confirm-content p {
    font-weight: 300;
  }
}
@media only screen and (min-width: 720px) and (max-width: 959px) {
  .dashboard_1JQEF {
    background-image: url(/static/media/dashboard-background.52caada9.svg);
    background-repeat: no-repeat;
    background-position: calc(75vw / 2) calc(-50vh / 2);
    background-color: #fff;
    display: flex;
    display: -webkit-flexbox;
    justify-content: center;
    min-height: 100%;
  }
  .dashboard_1JQEF .layout_3KVWE {
    width: 100%;
    min-height: 400px;
    display: flex;
    display: -webkit-flexbox;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT {
    z-index: 1;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph {
    width: 100%;
    margin-top: auto;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .userName_1G0g6 > div {
    color: #0C3E57;
    font-size: 50px;
    font-weight: 600;
    line-height: 51px;
    overflow-wrap: break-word;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H > span {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .progress_14Ci0 {
    margin-bottom: 15px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .progress_14Ci0 > div {
    width: 120px !important;
    height: 120px !important;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .ant-progress-status-success .ant-progress-circle-path,
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .ant-progress-status-success .ant-progress-text {
    color: #29db73;
    stroke: #29db73 !important;
    font-size: 50px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph h3 {
    font-size: 14px;
    font-weight: normal;
    color: #0C3E57;
    margin-bottom: 30px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .action_3_4_X {
    display: flex;
    justify-content: flex-start;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .action_3_4_X button {
    color: #fff;
    background-color: #D672A9;
    border: unset;
    width: 180px;
    height: 36px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .action_3_4_X button > span {
    font-size: 14px;
    font-weight: 500;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .logo_29Q59 {
    margin-top: auto;
    margin-bottom: 50px;
    width: 220px;
  }
  .dashboard_1JQEF .layout_3KVWE .image_2uunV {
    width: 50%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    display: -webkit-flexbox;
    justify-content: center;
    align-items: center;
  }
  .dashboard_1JQEF .layout_3KVWE .image_2uunV > img {
    max-width: 50vw;
    max-height: 220px;
    height: auto;
  }
  .info_1BZcB {
    width: 75vw !important;
  }
  .info_1BZcB .ant-modal-content {
    width: 75vw;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.4);
  }
  .info_1BZcB .ant-modal-confirm-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  .info_1BZcB .ant-modal-confirm-content {
    font-size: 16px;
    text-align: justify;
  }
  .info_1BZcB .ant-modal-confirm-content p {
    font-weight: 300;
  }
}
@media only screen and (min-width: 960px) {
  .dashboard_1JQEF {
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .dashboard_1JQEF .layout_3KVWE {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT {
    z-index: 1;
    margin: 0 50px;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph {
    width: 100%;
    margin-top: auto;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H > span {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .progress_14Ci0 {
    margin-top: 15px;
    margin-right: 30px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .progress_14Ci0 > div {
    width: 160px !important;
    height: 160px !important;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H img {
    width: 60px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .ant-progress-status-success .ant-progress-circle-path,
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .baseline_2IS6H .ant-progress-status-success .ant-progress-text {
    color: #29db73;
    stroke: #29db73 !important;
    font-size: 60px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .userName_1G0g6 > div {
    color: #0C3E57;
    font-size: 95px;
    font-weight: 600;
    line-height: 103px;
    overflow-wrap: break-word;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph h3 {
    font-size: 24px;
    line-height: 34px;
    font-weight: normal;
    color: #0C3E57;
    margin-bottom: 50px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .action_3_4_X button {
    color: #fff;
    background-color: #D672A9;
    border: unset;
    width: 350px;
    height: 70px;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .content_19aph .action_3_4_X button > span {
    font-size: 24px;
    font-weight: 500;
  }
  .dashboard_1JQEF .layout_3KVWE > .left_8IsLT > .logo_29Q59 {
    margin-top: auto;
    margin-bottom: 50px;
    width: 300px;
  }
  .dashboard_1JQEF .layout_3KVWE .image_2uunV {
    padding: 50px;
    background-image: url(/static/media/dashboard-background.52caada9.svg);
    background-repeat: no-repeat;
    background-position: left;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dashboard_1JQEF .layout_3KVWE .image_2uunV > img {
    max-width: 35vw;
    max-height: 35vh;
    width: 100%;
    height: auto;
  }
}

.profile_3_LQz {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;
  padding: 50px;
  background-image: url(/static/media/background.cb2c36fd.svg);
  background-repeat: no-repeat;
}
@media only screen and (max-width: 719px) {
  .profile_3_LQz {
    background-size: 300vh;
    background-position: -25vw;
  }
}
@media only screen and (min-width: 720px) and (max-width: 959px) {
  .profile_3_LQz {
    background-size: 200vw;
    background-position: 25vw;
    padding: 30px;
  }
}
@media only screen and (min-width: 960px) {
  .profile_3_LQz {
    background-size: 200vw;
    background-position: 25vw;
  }
}
.profile_3_LQz .header_FEQId {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 20px;
}
.profile_3_LQz .container_MHF3S {
  display: grid;
  grid-template-columns: -webkit-max-content minmax(auto, 600px);
  grid-template-columns: max-content minmax(auto, 600px);
  grid-template-rows: auto;
  grid-column-gap: 50px;
  min-height: 100%;
  align-items: center;
}
.profile_3_LQz .container_MHF3S .description_W91NW {
  max-width: 330px;
}
.profile_3_LQz .container_MHF3S .description_W91NW > p {
  font-size: 22px;
  font-weight: 500;
}
.profile_3_LQz .container_MHF3S .content__IMNH {
  background-color: #fff;
  min-height: 550px;
  border-radius: 9px;
  box-shadow: 0 1px 80px #03172B4C;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .profile_3_LQz .container_MHF3S .content__IMNH {
    padding: 30px;
  }
}
@media only screen and (min-width: 960px) {
  .profile_3_LQz .container_MHF3S .content__IMNH {
    padding: 30px 50px;
  }
}
.profile_3_LQz .container_MHF3S .content__IMNH .options_2q2P- {
  display: flex;
  height: 100%;
  flex: 1 1 auto;
}
.profile_3_LQz .container_MHF3S .content__IMNH .options_2q2P- .ant-select {
  width: 100%;
}
.profile_3_LQz .container_MHF3S .content__IMNH .actions_2GQHL {
  display: flex;
  justify-content: space-between;
}
.profile_3_LQz .container_MHF3S .content__IMNH .actions_2GQHL button {
  border: 1px solid #70CDE9;
  border-radius: 36px;
  width: 100px;
  height: 50px;
  color: #fff;
  background-color: #70CDE9;
  font-size: 16px;
}
.profile_3_LQz .container_MHF3S .content__IMNH .actions_2GQHL button > span {
  font-weight: 500;
}
.profile_3_LQz .container_MHF3S .content__IMNH .actions_2GQHL button[disabled] {
  opacity: 0.4;
}
.profile_3_LQz > .logo_oLIuj {
  margin-top: auto;
  margin-bottom: 50px;
  width: 200px;
}

.root_3SyN1 {
  width: 100%;
  flex: 1 0 auto;
}
.root_3SyN1 > .header_PEkZT {
  display: flex;
  justify-content: space-between;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 10px;
}
.root_3SyN1 > .header_PEkZT > .title_2uHn_ {
  font-size: 32px;
  font-weight: 500;
}
.root_3SyN1 > .header_PEkZT > .index_37PXF {
  font-size: 18px;
  font-weight: 500;
}
.root_3SyN1 > .options_3ltbK {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.root_3SyN1 > .options_3ltbK > button {
  margin-bottom: 20px;
  height: 50px;
  font-size: 24px;
  color: #fff;
  opacity: 0.3;
  background-color: #D672A9;
  border: 2px solid #D672A9;
  border-radius: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.root_3SyN1 > .options_3ltbK > button > span {
  font-weight: 400;
}
.root_3SyN1 > .options_3ltbK > button:hover {
  color: #fff;
  background-color: #D672A9;
  opacity: 1;
}
.root_3SyN1 > .options_3ltbK > .selected_1aFXJ {
  color: #fff;
  background-color: #D672A9;
  opacity: 1;
}

@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .container_ga0Aj {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 400px;
  }
}
@media only screen and (min-width: 960px) {
  .container_ga0Aj {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
  }
}
.ant-spin-dot_cUBeW {
  width: 40px;
  height: 40px;
}
.ant-spin-dot-item_34mC5 {
  width: 20px;
  height: 20px;
}
.ant-spin-nested-loading_3xjmt > div > .ant-spin_3cCjC {
  max-height: none;
  min-height: 100%;
}

.ant-notification {
  width: auto;
  max-width: 600px;
  overflow-wrap: break-word;
}
.notification_1BHVl .ant-notification-notice-message {
  font-weight: 500;
  font-size: 16px;
}
.notification_1BHVl .ant-notification-notice-description {
  font-size: 16px;
  margin: 0 50px 0 60px;
}
.notification_1BHVl .ant-notification-notice-message {
  margin: 0 50px 0 60px;
}
.notification_1BHVl .ant-notification-notice-with-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.notification_1BHVl .ant-notification-notice-with-icon > i {
  font-size: 32px;
}

.root_rZWIP {
  width: 100%;
  flex: 1 0 auto;
}
.root_rZWIP > .header_3vYQr {
  display: flex;
  justify-content: space-between;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 10px;
}
.root_rZWIP > .header_3vYQr > .title_1glyE {
  font-size: 32px;
  font-weight: 500;
}
.root_rZWIP > .header_3vYQr > .index_3e3qx {
  font-size: 18px;
  font-weight: 500;
}
.root_rZWIP .options_1qnfd {
  width: 100%;
  height: calc(100% - 75px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.root_rZWIP .options_1qnfd .ant-input-number {
  display: block;
  width: 100%;
  height: 50px;
  background-color: rgba(214, 114, 169, 0.05);
  border: 2px solid #D672A9;
  border-radius: 36px;
}
.root_rZWIP .options_1qnfd .ant-input-number input {
  display: flex;
  text-align: center;
  margin-top: 9px;
  font-size: 18px;
  font-weight: 500;
}
.root_rZWIP .options_1qnfd .ant-input-number input::-webkit-input-placeholder {
  color: #D672A9;
  opacity: 0.5;
}
.root_rZWIP .options_1qnfd .ant-input-number input:-ms-input-placeholder {
  color: #D672A9;
  opacity: 0.5;
}
.root_rZWIP .options_1qnfd .ant-input-number input::-ms-input-placeholder {
  color: #D672A9;
  opacity: 0.5;
}
.root_rZWIP .options_1qnfd .ant-input-number input::placeholder {
  color: #D672A9;
  opacity: 0.5;
}
.root_rZWIP .options_1qnfd .ant-input-number .ant-input-number-handler-wrap {
  width: 26px;
  right: 2px;
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
}
.root_rZWIP .options_1qnfd .ant-input-number .ant-input-number-handler-wrap > span:first-of-type {
  border-top-right-radius: 36px;
}
.root_rZWIP .options_1qnfd .ant-input-number .ant-input-number-handler-wrap > span:last-of-type {
  border-bottom-right-radius: 36px;
}
.root_rZWIP .options_1qnfd .ant-input-number .ant-input-number-handler-wrap .anticon {
  margin-right: 3px;
}

@media only screen and (max-width: 719px) {
  .dashboard_2pd2K {
    background-image: url(/static/media/dashboard-mobile-background.fec78743.svg);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    background-color: #fff;
    display: flex;
    display: -webkit-flexbox;
    justify-content: center;
    min-height: 100%;
  }
  .dashboard_2pd2K .layout_3A1V1 {
    width: 100%;
    display: flex;
    display: -webkit-flexbox;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- {
    z-index: 1;
    padding: 25px;
    width: 100%;
    display: flex;
    display: -webkit-flexbox;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN {
    width: 100%;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN h1 {
    font-size: 50px;
    line-height: 50px;
    font-weight: 600;
    margin: 25px 0;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN h2 {
    font-size: 20px;
    margin: 25px 0;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN .action_3lzNM {
    display: flex;
    justify-content: center;
    margin: 50px 0 0;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN .action_3lzNM button {
    color: #fff;
    background-color: #D672A9;
    border-radius: 36px;
    border: unset;
    width: 100%;
    height: 50px;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN .action_3lzNM button > span {
    font-size: 20px;
    font-weight: 500;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- .logo_EE-mV {
    margin: 50px;
    width: 300px;
  }
  .dashboard_2pd2K .layout_3A1V1 .image_3f5or {
    width: 100%;
    display: flex;
    display: -webkit-flexbox;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }
  .dashboard_2pd2K .layout_3A1V1 .image_3f5or > img {
    max-width: 45vw;
    max-height: 250px;
    width: 100%;
    height: auto;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg {
    position: fixed;
    right: 15px;
    top: 15px;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg > button {
    width: 80px;
    height: 40px;
    border: unset;
    border-radius: 36px;
    background-color: #fff;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg > button a {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg > button:hover {
    opacity: 0.5;
  }
}
@media only screen and (min-width: 720px) and (max-width: 959px) {
  .dashboard_2pd2K {
    background-image: url(/static/media/dashboard-background.52caada9.svg);
    background-repeat: no-repeat;
    background-position: calc(75vw / 2) calc(-50vh / 2);
    background-color: #fff;
    display: flex;
    display: -webkit-flexbox;
    justify-content: center;
    min-height: 100%;
  }
  .dashboard_2pd2K .layout_3A1V1 {
    width: 100%;
    min-height: 400px;
    display: flex;
    display: -webkit-flexbox;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- {
    z-index: 1;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN {
    width: 100%;
    margin-top: auto;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN h1 {
    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
    margin: 25px 0;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN h2 {
    font-size: 24px;
    margin: 25px 0;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN .action_3lzNM {
    display: flex;
    justify-content: flex-start;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN .action_3lzNM button {
    color: #fff;
    background-color: #D672A9;
    border-radius: 36px;
    border: unset;
    width: 300px;
    height: 50px;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN .action_3lzNM button > span {
    font-size: 18px;
    font-weight: 500;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .logo_EE-mV {
    margin-top: auto;
    margin-bottom: 50px;
    width: 220px;
  }
  .dashboard_2pd2K .layout_3A1V1 .image_3f5or {
    width: 50%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    display: -webkit-flexbox;
    justify-content: center;
    align-items: center;
  }
  .dashboard_2pd2K .layout_3A1V1 .image_3f5or > img {
    max-width: 50vw;
    max-height: 220px;
    height: auto;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg {
    position: fixed;
    right: 35px;
    top: 35px;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg > button {
    width: 100px;
    height: 50px;
    border: unset;
    border-radius: 36px;
    background-color: #fff;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg > button a {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg > button:hover {
    opacity: 0.5;
  }
}
@media only screen and (min-width: 960px) {
  .dashboard_2pd2K {
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .dashboard_2pd2K .layout_3A1V1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- {
    z-index: 1;
    margin: 0 50px;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN {
    width: 100%;
    margin-top: auto;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN h1 {
    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
    margin: 25px 0;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN h2 {
    font-size: 24px;
    margin: 25px 0;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN .action_3lzNM {
    margin: 25px 0;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN .action_3lzNM button {
    color: #fff;
    background-color: #D672A9;
    border-radius: 36px;
    border: unset;
    width: 300px;
    height: 70px;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .content_19dXN .action_3lzNM button > span {
    font-size: 24px;
    font-weight: 500;
  }
  .dashboard_2pd2K .layout_3A1V1 > .left_fzpo- > .logo_EE-mV {
    margin-top: auto;
    margin-bottom: 50px;
    width: 300px;
  }
  .dashboard_2pd2K .layout_3A1V1 .image_3f5or {
    padding: 50px;
    background-image: url(/static/media/dashboard-background.52caada9.svg);
    background-repeat: no-repeat;
    background-position: left;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dashboard_2pd2K .layout_3A1V1 .image_3f5or > img {
    max-width: 35vw;
    max-height: 35vh;
    width: 100%;
    height: auto;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg {
    position: fixed;
    right: 35px;
    top: 35px;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg > button {
    width: 100px;
    height: 50px;
    border: unset;
    border-radius: 36px;
    background-color: #fff;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg > button a {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }
  .dashboard_2pd2K .layout_3A1V1 .exit_3jubg > button:hover {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .summary_2mMTL {
    background-color: #fff;
    padding-bottom: 30px;
    min-height: 100%;
  }
  .summary_2mMTL .layout_1_zkI {
    display: flex;
    flex-direction: column;
  }
  .summary_2mMTL .layout_1_zkI .header_1q_Cv {
    padding: 0 5% 20px;
    margin: 0;
  }
  .summary_2mMTL .layout_1_zkI .header_1q_Cv h1 {
    font-size: 36px;
    font-weight: 600;
    margin: 10px 0;
  }
  .summary_2mMTL .layout_1_zkI .header_1q_Cv .subheader_106YG {
    font-size: 18px;
    font-weight: normal;
    margin: 20px 0 0;
  }
  .summary_2mMTL .layout_1_zkI .actions_110A4 {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
  }
  .summary_2mMTL .layout_1_zkI .actions_110A4 .finished_2zfwa {
    border: 1px #D672A9 solid;
    color: #D672A9;
    height: 49px;
    width: 100%;
    margin: 10px 0;
  }
  .summary_2mMTL .layout_1_zkI .actions_110A4 .finished_2zfwa > span {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
  }
  .summary_2mMTL .layout_1_zkI .actions_110A4 .finished_2zfwa:hover {
    opacity: 0.5;
  }
  .summary_2mMTL .layout_1_zkI .actions_110A4 .learnMore_38NVq {
    width: 100%;
    height: 49px;
    border: 1px solid #70CDE9;
    background-color: rgba(112, 205, 233, 0.1);
    color: #70CDE9;
    font-size: 16px;
  }
  .summary_2mMTL .layout_1_zkI .actions_110A4 .learnMore_38NVq:hover {
    color: #fff;
    background-color: #70CDE9;
  }
  .summary_2mMTL .layout_1_zkI .actions_110A4 .learnMore_38NVq > span {
    font-weight: 500;
  }
  .summary_2mMTL .layout_1_zkI .cup_6p-D0 {
    background-image: url(/static/media/summary-mobile-background.63a424f2.svg);
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 20px 50px;
  }
  .summary_2mMTL .layout_1_zkI .cup_6p-D0 > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .summary_2mMTL .layout_1_zkI .cup_6p-D0 > div .legend_26JJi {
    font-size: 24px;
    line-height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .summary_2mMTL .layout_1_zkI .cup_6p-D0 > div .legend_26JJi > .health_3v_oS {
    color: #70CDE9;
  }
  .summary_2mMTL .layout_1_zkI .cup_6p-D0 > div .legend_26JJi > .happiness_384ru {
    color: #D672A9;
  }
  .summary_2mMTL .layout_1_zkI .cup_6p-D0 > div .legend_26JJi > .success_1Acwc {
    color: #FFB12F;
  }
  .summary_2mMTL .layout_1_zkI .cup_6p-D0 > div .legend_26JJi > .health_3v_oS,
  .summary_2mMTL .layout_1_zkI .cup_6p-D0 > div .legend_26JJi .happiness_384ru,
  .summary_2mMTL .layout_1_zkI .cup_6p-D0 > div .legend_26JJi .success_1Acwc {
    font-weight: 600;
  }
}
@media only screen and (min-width: 960px) {
  .summary_2mMTL {
    background-color: #fff;
    min-height: 100%;
    display: flex;
  }
  .summary_2mMTL .layout_1_zkI {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 {
    max-width: 664px;
    width: 100%;
    padding: 50px 0 50px 50px;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .header_1q_Cv h1 {
    font-size: calc(30px + 2vw);
    line-height: normal;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .header_1q_Cv .subheader_106YG {
    font-size: calc(10px + 1.5vw);
    line-height: normal;
    font-weight: normal;
    margin: 30px 0;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 {
    display: flex;
    flex-direction: column;
    width: 400px;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .feedback_1-d9b {
    background-color: #D672A9;
    border: unset;
    border-radius: 36px;
    color: #fff;
    height: 49px;
    width: 100%;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .feedback_1-d9b > span {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .feedback_1-d9b:hover {
    color: #fff;
    background-color: #D672A9;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .feedback_1-d9b:active,
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .feedback_1-d9b:focus {
    color: #fff;
    background-color: #D672A9;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .finished_2zfwa {
    margin-top: 20px;
    margin-right: 10px;
    background-color: #fff;
    border: 1px #D672A9 solid;
    border-radius: 36px;
    color: #D672A9;
    height: 49px;
    width: 100%;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .finished_2zfwa > span {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .finished_2zfwa:hover {
    opacity: 0.5;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .learnMore_38NVq {
    width: 100%;
    margin-top: 20px;
    height: 49px;
    border: 1px solid #70CDE9;
    border-radius: 36px;
    background-color: rgba(112, 205, 233, 0.1);
    color: #70CDE9;
    font-size: 16px;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .learnMore_38NVq:hover {
    color: #fff;
    background-color: #70CDE9;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 .learnMore_38NVq > span {
    font-weight: 500;
  }
  .summary_2mMTL .layout_1_zkI .left_11TQ7 .content_3H287 > .first_1FrxS .actions_110A4 > div:nth-of-type(1) {
    margin-right: 20px;
  }
  .summary_2mMTL .layout_1_zkI .right_2A3N_ {
    background-image: url(/static/media/summary-background.8a090630.svg);
    background-position: left;
    background-size: auto 1920px;
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .summary_2mMTL .layout_1_zkI .right_2A3N_ .cup_6p-D0 > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .summary_2mMTL .layout_1_zkI .right_2A3N_ .cup_6p-D0 > div .legend_26JJi {
    font-size: 42px;
    line-height: 54px;
    margin: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .summary_2mMTL .layout_1_zkI .right_2A3N_ .cup_6p-D0 > div .legend_26JJi > .health_3v_oS {
    color: #70CDE9;
  }
  .summary_2mMTL .layout_1_zkI .right_2A3N_ .cup_6p-D0 > div .legend_26JJi > .happiness_384ru {
    color: #D672A9;
  }
  .summary_2mMTL .layout_1_zkI .right_2A3N_ .cup_6p-D0 > div .legend_26JJi > .success_1Acwc {
    color: #FFB12F;
  }
  .summary_2mMTL .layout_1_zkI .right_2A3N_ .cup_6p-D0 > div .legend_26JJi > .health_3v_oS,
  .summary_2mMTL .layout_1_zkI .right_2A3N_ .cup_6p-D0 > div .legend_26JJi .happiness_384ru,
  .summary_2mMTL .layout_1_zkI .right_2A3N_ .cup_6p-D0 > div .legend_26JJi .success_1Acwc {
    font-weight: 600;
  }
}

.tcup__44xI {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 50px;
}
.tcup__44xI .content_a4Jf5 {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}
.tcup__44xI .content_a4Jf5 .svgTCup_t2M3b {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.tcup__44xI .content_a4Jf5 .svgTCup_t2M3b rect {
  max-height: 0;
}
.tcup__44xI .content_a4Jf5 .svgTCup_t2M3b .empty_RrMAJ {
  fill: #fff;
}
.tcup__44xI .content_a4Jf5 .shadow_2Wt0T {
  position: absolute;
  left: -5%;
  z-index: 0;
  width: 110%;
}
@-webkit-keyframes fill-tcup_SNVA5 {
  from {
    y: 100%;
    height: 0;
  }
  to {
    height: 100%;
  }
}
@keyframes fill-tcup_SNVA5 {
  from {
    y: 100%;
    height: 0;
  }
  to {
    height: 100%;
  }
}
.tcup__44xI .content_a4Jf5 .animated_5o2Qp {
  position: absolute;
  -webkit-animation-name: fill-tcup_SNVA5;
          animation-name: fill-tcup_SNVA5;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  transform-origin: bottom;
}
.tcup__44xI .animation_JeqId {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  transform: translate(50%, 35%);
  margin: auto;
}

.info_2sUja {
  max-width: 800px;
}
.info_2sUja .ant-modal-content {
  border-radius: 10px;
}
.info_2sUja .ant-modal-confirm-content {
  font-size: 18px;
  border-radius: 10px;
}
.info_2sUja .ant-modal-confirm-btns {
  float: unset;
  text-align: center;
  margin-top: 50px;
}
.info_2sUja .ant-modal-confirm-btns > button {
  border: unset;
  border-radius: 36px;
  width: 100px;
  height: 50px;
}
@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .question_1JplG {
    height: 100%;
  }
  .question_1JplG > div {
    height: 100vh;
  }
  .question_1JplG > div .content_1oyJU {
    height: 100vh;
    display: flex;
    flex-direction: column-reverse;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz {
    height: 60%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .header_n-Wj8 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .header_n-Wj8 > div {
    font-weight: 500;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .header_n-Wj8 > div button {
    padding: 0;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .header_n-Wj8 > div button span {
    font-weight: 500;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .category_1RmEz {
    font-size: 26px;
    font-weight: 400;
    margin: 20px 0;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .category_1RmEz > span {
    font-weight: 600;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .text_3oeb- {
    font-size: 20px;
    font-weight: 400;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100px;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .buttons_IoRKN {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .buttons_IoRKN .roundButton_3eDqe {
    width: 16px;
    height: 16px;
    mask: url(/static/media/ellipse.ef118565.svg) no-repeat center;
    -webkit-mask: url(/static/media/ellipse.ef118565.svg) no-repeat center;
    mask-size: 16px;
    -webkit-mask-size: 16px;
    border: unset;
    cursor: pointer;
    transition: 200ms all;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .buttons_IoRKN .roundButton_3eDqe:hover {
    transform: scale(2);
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .buttons_IoRKN .active {
    transform: scale(2);
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .labels_399e- {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .labels_399e- > div {
    font-weight: 500;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .navigation_2ZjJw {
    display: flex;
    justify-content: center;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .navigation_2ZjJw .submit_Wipt5 {
    height: 40px;
    width: 100%;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .navigation_2ZjJw .submit_Wipt5 span {
    padding: 0 10px;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .navigation_2ZjJw button span {
    font-weight: 500;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z {
    background-color: #F5F8FB;
    width: 100%;
    height: 260px;
    position: relative;
    margin: 0;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z > .image_378HV,
  .question_1JplG > div .content_1oyJU > .right_14w0Z .currentImage_3tKrf {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all 0.25s ease-in-out;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z > .image_378HV.visible_1H2R9,
  .question_1JplG > div .content_1oyJU > .right_14w0Z .currentImage_3tKrf.visible_1H2R9 {
    opacity: 1;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z > .image_378HV.hidden_ub5Z2,
  .question_1JplG > div .content_1oyJU > .right_14w0Z .currentImage_3tKrf.hidden_ub5Z2 {
    opacity: 0;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z .exit_EGrcG {
    position: fixed;
    right: 15px;
    top: 15px;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z .exit_EGrcG > button {
    width: 80px;
    height: 40px;
    border: unset;
    border-radius: 36px;
    background-color: #FFB12F;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z .exit_EGrcG > button a {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z .exit_EGrcG > button:hover {
    opacity: 0.5;
  }
}
@media only screen and (min-width: 960px) {
  .question_1JplG {
    height: 100vh;
  }
  .question_1JplG .ant-spin-nested-loading > div > .ant-spin,
  .question_1JplG .ant-spin-container,
  .question_1JplG .ant-spin-container > div {
    max-height: none;
    height: 100%;
  }
  .question_1JplG > div {
    height: 100%;
  }
  .question_1JplG > div .content_1oyJU {
    height: 100%;
    display: flex;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz {
    padding: 50px;
    height: 100%;
    width: 50%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .top_3hSQN .header_n-Wj8 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .top_3hSQN .header_n-Wj8 > div {
    font-size: 16px;
    font-weight: 500;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .top_3hSQN .header_n-Wj8 > div button {
    padding: 0;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .top_3hSQN .header_n-Wj8 > div button span {
    font-size: 16px;
    font-weight: 500;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .top_3hSQN .category_1RmEz {
    font-size: 30px;
    font-weight: 400;
    margin: 20px 0;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .top_3hSQN .category_1RmEz > span {
    font-weight: 600;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .top_3hSQN .text_3oeb- {
    font-size: 20px;
    font-weight: 400;
    height: 100px;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .buttons_IoRKN {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .buttons_IoRKN .roundButton_3eDqe {
    width: 40px;
    height: 40px;
    border: 3px solid #FFF;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    background: #000;
    cursor: pointer;
    transition: 200ms all;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .buttons_IoRKN .active {
    transform: scale(2);
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .labels_399e- {
    display: flex;
    justify-content: space-between;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .answers_3Dj3D .labels_399e- > div {
    font-size: 16px;
    font-weight: 500;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .navigation_2ZjJw {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .navigation_2ZjJw .submit_Wipt5 {
    width: 100%;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .navigation_2ZjJw .submit_Wipt5 span {
    padding: 0 10px;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .navigation_2ZjJw button {
    height: 50px;
  }
  .question_1JplG > div .content_1oyJU > .left_299Mz .layout_23MWN .navigation_2ZjJw button span {
    font-size: 16px;
    font-weight: 400;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z {
    background-color: #F5F8FB;
    width: 50%;
    height: 100%;
    position: relative;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z > .image_378HV,
  .question_1JplG > div .content_1oyJU > .right_14w0Z .currentImage_3tKrf {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    transition: all 0.5s ease-in-out;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z > .image_378HV.visible_1H2R9,
  .question_1JplG > div .content_1oyJU > .right_14w0Z .currentImage_3tKrf.visible_1H2R9 {
    opacity: 1;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z > .image_378HV.hidden_ub5Z2,
  .question_1JplG > div .content_1oyJU > .right_14w0Z .currentImage_3tKrf.hidden_ub5Z2 {
    opacity: 0;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z .exit_EGrcG {
    position: fixed;
    right: 35px;
    top: 35px;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z .exit_EGrcG > button {
    width: 100px;
    height: 50px;
    border: unset;
    border-radius: 36px;
    background-color: #FFB12F;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z .exit_EGrcG > button a {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
  .question_1JplG > div .content_1oyJU > .right_14w0Z .exit_EGrcG > button:hover {
    opacity: 0.5;
  }
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #fff;
}
.mobileAnswers_2LHUQ {
  width: 100%;
}
.mobileAnswers_2LHUQ .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: unset !important;
}
.mobileAnswers_2LHUQ .ant-slider-rail {
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(135deg, #ec558d 0%, #f1d05e 50%, #1cd59f 100%);
  height: 6px;
  border-radius: 10px;
}
.mobileAnswers_2LHUQ .ant-slider-dot {
  display: none;
}
.mobileAnswers_2LHUQ .ant-slider-mark-text > div {
  margin-top: 14px;
  border-left: 1px solid var(--color);
  height: 10px;
}
.mobileAnswers_2LHUQ .ant-slider-mark-text-active > div {
  height: 16px;
}
.mobileAnswers_2LHUQ .ant-slider-handle {
  margin: 0;
  border: 10px solid #fff;
  box-shadow: 0 0 12px #03172B1C;
  background-color: var(--selectedColor);
  width: 36px;
  height: 36px;
  top: -100%;
}

@media only screen and (min-width: 720px) and (max-width: 959px), only screen and (min-width: 960px) {
  .layout_1cdu3 {
    height: 100%;
  }
}
.layout_1cdu3 .ant-spin-nested-loading,
.layout_1cdu3 .ant-spin-nested-loading > div > .ant-spin,
.layout_1cdu3 .ant-spin-container,
.layout_1cdu3 .ant-spin-container > div {
  max-height: none;
  height: 100%;
}
.layout_1cdu3 .content_n3yl7 {
  height: 100%;
}
.layout_1cdu3 .content_n3yl7 .carousel_4h8JB {
  height: 100%;
}
.layout_1cdu3 .content_n3yl7 .carousel_4h8JB .questions_1XLZ6 {
  height: 80%;
}
.layout_1cdu3 .content_n3yl7 .carousel_4h8JB .navigation_3eOFn {
  background-color: #fff;
  height: 20%;
}

.info_1DYEP {
  max-width: 800px;
}
.info_1DYEP .ant-modal-content {
  border-radius: 10px;
}
.info_1DYEP .ant-modal-confirm-content {
  font-size: 18px;
  border-radius: 10px;
}
.info_1DYEP .ant-modal-confirm-btns {
  float: unset;
  text-align: center;
  margin-top: 50px;
}
.info_1DYEP .ant-modal-confirm-btns > button {
  border: unset;
  border-radius: 36px;
  width: 100px;
  height: 50px;
}
@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .question_2pyyH {
    height: 100%;
  }
  .question_2pyyH > div {
    height: 100%;
  }
  .question_2pyyH > div .content_2qcMh {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U {
    height: 60%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- > div {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- > div button {
    padding: 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .header_2X-g- > div button span {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .category_1md9v {
    font-size: 26px;
    font-weight: 400;
    margin: 20px 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .category_1md9v > span {
    font-weight: 600;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .text_2TQvq {
    font-size: 20px;
    font-weight: 400;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .buttons_2CipU {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .buttons_2CipU .roundButton_1Ubo5 {
    width: 16px;
    height: 16px;
    mask: url(/static/media/ellipse.ef118565.svg) no-repeat center;
    -webkit-mask: url(/static/media/ellipse.ef118565.svg) no-repeat center;
    mask-size: 16px;
    -webkit-mask-size: 16px;
    border: unset;
    cursor: pointer;
    transition: 200ms all;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .buttons_2CipU .roundButton_1Ubo5:hover {
    transform: scale(2);
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .buttons_2CipU .active {
    transform: scale(2);
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .labels_3vH-P {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .labels_3vH-P > div {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu {
    display: flex;
    justify-content: space-between;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu .prev_lgmWB {
    padding: 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu .next_2GQc5 span {
    padding: 0 10px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu button span {
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee {
    background-color: #F5F8FB;
    width: 100%;
    height: 260px;
    position: relative;
    margin: 0;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all 0.25s ease-in-out;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn.visible_1ccyD,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT.visible_1ccyD {
    opacity: 1;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn.hidden_3vly0,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT.hidden_3vly0 {
    opacity: 0;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .exit_1VXL_ {
    position: fixed;
    right: 15px;
    top: 15px;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .exit_1VXL_ > button {
    width: 80px;
    height: 40px;
    border: unset;
    border-radius: 36px;
    background-color: #FFB12F;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .exit_1VXL_ > button a {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .exit_1VXL_ > button:hover {
    opacity: 0.5;
  }
}
@media only screen and (min-width: 960px) {
  .question_2pyyH {
    height: 100%;
  }
  .question_2pyyH .ant-spin-nested-loading > div > .ant-spin,
  .question_2pyyH .ant-spin-container,
  .question_2pyyH .ant-spin-container > div {
    max-height: none;
    height: 100%;
  }
  .question_2pyyH > div {
    height: 100%;
  }
  .question_2pyyH > div .content_2qcMh {
    height: 100%;
    display: flex;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U {
    padding: 50px;
    height: 100%;
    width: 50%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .top_7PiXz .header_2X-g- {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .top_7PiXz .header_2X-g- > div {
    font-size: 16px;
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .top_7PiXz .header_2X-g- > div button {
    padding: 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .top_7PiXz .header_2X-g- > div button span {
    font-size: 16px;
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .top_7PiXz .category_1md9v {
    font-size: 30px;
    font-weight: 400;
    margin: 20px 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .top_7PiXz .category_1md9v > span {
    font-weight: 600;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .top_7PiXz .text_2TQvq {
    font-size: 20px;
    font-weight: 400;
    height: 100px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .buttons_2CipU {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .buttons_2CipU .roundButton_1Ubo5 {
    width: 40px;
    height: 40px;
    border: 3px solid #FFF;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    background: #000;
    cursor: pointer;
    transition: 200ms all;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .buttons_2CipU .active {
    transform: scale(2);
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .labels_3vH-P {
    display: flex;
    justify-content: space-between;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .answers_3Dycq .labels_3vH-P > div {
    font-size: 16px;
    font-weight: 500;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu .prev_lgmWB {
    padding: 0;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu .next_2GQc5 span {
    padding: 0 10px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu button {
    height: 50px;
  }
  .question_2pyyH > div .content_2qcMh > .left_2N-6U .layout_2-e7Z .navigation_iDnPu button span {
    font-size: 16px;
    font-weight: 400;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee {
    background-color: #F5F8FB;
    width: 50%;
    height: 100%;
    position: relative;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    transition: all 0.5s ease-in-out;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn.visible_1ccyD,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT.visible_1ccyD {
    opacity: 1;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee > .image_uHzzn.hidden_3vly0,
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .currentImage_FpxrT.hidden_3vly0 {
    opacity: 0;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .exit_1VXL_ {
    position: fixed;
    right: 35px;
    top: 35px;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .exit_1VXL_ > button {
    width: 100px;
    height: 50px;
    border: unset;
    border-radius: 36px;
    background-color: #FFB12F;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .exit_1VXL_ > button a {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
  .question_2pyyH > div .content_2qcMh > .right_Zf9Ee .exit_1VXL_ > button:hover {
    opacity: 0.5;
  }
}

.feedback_TpHyM {
  background-color: #F5F8FB;
  height: 100%;
  padding: 50px;
}
.feedback_TpHyM > .header_1uQG6 {
  font-size: 50px;
  font-weight: 600;
}
.feedback_TpHyM > .modal_2ZHQg {
  display: flex;
  justify-content: center;
  margin: 50px;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo {
  padding: 25px;
  background-color: #fff;
  box-shadow: 0 0 12px #03172B1C;
  border-radius: 10px;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo > .header_1uQG6 {
  font-size: 18px;
  font-weight: 600;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .rates_LxexO {
  margin: 10px 0;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .rates_LxexO > div {
  font-size: 18px;
  font-weight: 500;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .rates_LxexO > .rating_2ULdq {
  width: 100%;
  text-align: center;
}
@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .rates_LxexO > .rating_2ULdq {
    font-size: 48px;
  }
}
@media only screen and (min-width: 960px) {
  .feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .rates_LxexO > .rating_2ULdq {
    font-size: 72px;
  }
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .comment_2yKOx > textarea {
  color: #348cab;
  font-size: 18px;
  border-radius: 18px;
  border: 1px #13c2c2 solid !important;
  background-color: rgba(112, 205, 233, 0.05);
  padding: 10px 15px;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .comment_2yKOx > textarea::-webkit-input-placeholder {
  color: #70cde980;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .comment_2yKOx > textarea:-ms-input-placeholder {
  color: #70cde980;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .comment_2yKOx > textarea::-ms-input-placeholder {
  color: #70cde980;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .comment_2yKOx > textarea::placeholder {
  color: #70cde980;
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .comment_2yKOx > textarea:active,
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .comment_2yKOx > textarea:focus,
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .comment_2yKOx > textarea:visited {
  border: 1px #70cde9 solid;
  box-shadow: 0 0 0 2px rgba(112, 205, 233, 0.2);
}
.feedback_TpHyM > .modal_2ZHQg > .content_2GmDo .comment_2yKOx .textLimit_13_WW {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #70cde9;
}
.feedback_TpHyM > .modal_2ZHQg .footer_uie4v {
  margin: 20px 0 0;
  display: flex;
  justify-content: space-between;
}
.feedback_TpHyM > .modal_2ZHQg .footer_uie4v > button {
  border-radius: 36px;
  width: 100px;
  height: 50px;
}
.feedback_TpHyM > .modal_2ZHQg .footer_uie4v > button > span {
  font-size: 14px;
  font-weight: 500;
}

