@import "src/styles/resolutions";

.profile {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100%;
    padding: 50px;

    background-image: url('./assets/background.svg');
    background-repeat: no-repeat;
    @media @mobile {
        background-size: 300vh;
        background-position: -25vw;
    }
    @media @tablet {
        background-size: 100vw + 100vh;
        background-position: 25vw;
        padding: 30px;
    }
    @media @desktop {
        background-size: 100vw + 100vh;
        background-position: 25vw;
    }

    .header {
        font-size: 50px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .container {

        display: grid;
        grid-template-columns: max-content minmax(auto, 600px);
        grid-template-rows: auto;
        grid-column-gap: 50px;
        min-height: 100%;
        align-items: center;

        .description {
            max-width: 330px;
            > p {
                font-size: 22px;
                font-weight: 500;
            }
        }

        .content {

            background-color: #fff;
            @media @mobile, @tablet {
                padding: 30px;
            }
            @media @desktop {
                padding: 30px 50px;
            }
            min-height: 550px;
            border-radius: 9px;
            box-shadow: 0 1px 80px #03172B4C;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .options {

                display: flex;
                height: 100%;
                flex: 1 1 auto;

                :global {
                    .ant-select {
                        width: 100%;
                    }
                }
            }

            .actions {
                display: flex;
                justify-content: space-between;

                button {
                    border: 1px solid #70CDE9;
                    border-radius: 36px;
                    width: 100px;
                    height: 50px;
                    color: #fff;
                    background-color: #70CDE9;
                    font-size: 16px;

                    > span {
                        font-weight: 500;
                    }

                    &[disabled] {
                        opacity: 0.4;
                    }
                }
            }
        }
    }

    > .logo {
        margin-top: auto;
        margin-bottom: 50px;
        width: 200px;
    }

}