.dashboard {
    background-image: url('../../dashboard/assets/dashboard-mobile-background.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    background-color: #fff;
    display: flex;
    display: -webkit-flex;
    display: -webkit-flexbox;
    justify-content: center;
    min-height: 100%;

    .layout {

        width: 100%;
        display: flex;
        display: -webkit-flex;
        display: -webkit-flexbox;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;

        > .left {

            z-index: 1;
            padding: 25px;
            width: 100%;
            display: flex;
            display: -webkit-flex;
            display: -webkit-box;
            display: -webkit-flexbox;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            > .content {

                width: 100%;

                h1 {
                    font-size: 50px;
                    line-height: 50px;
                    font-weight: 600;
                    margin: 25px 0;
                }

                h2 {
                    font-size: 20px;
                    margin: 25px 0;
                }

                .action {

                    display: flex;
                    justify-content: center;
                    margin: 50px 0 0;

                    button {
                        color: #fff;
                        background-color: #D672A9;
                        border-radius: 36px;
                        border: unset;
                        width: 100%;
                        height: 50px;

                        > span {
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                }
            }

            .logo {
                margin: 50px;
                width: 300px;
            }
        }

        .image {
            width: 100%;
            display: flex;
            display: -webkit-flex;
            display: -webkit-box;
            display: -webkit-flexbox;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 50px;

            > img {
                max-width: 45vw;
                max-height: 250px;
                width: 100%;
                height: auto;
            }
        }

        .exit {
            position: fixed;
            right: 15px;
            top: 15px;

            > button {
                width: 80px;
                height: 40px;
                border: unset;
                border-radius: 36px;
                background-color: #fff;

                a {
                    color: #000;
                    font-size: 18px;
                    font-weight: 600;
                }

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}