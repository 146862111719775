.dashboard {
    background-image: url('./assets/dashboard-background.svg');
    background-repeat: no-repeat;
    background-position: calc(75vw / 2) calc(-50vh / 2);
    background-color: #fff;
    display: flex;
    display: -webkit-flex;
    display: -webkit-flexbox;
    justify-content: center;
    min-height: 100%;

    .layout {

        width: 100%;
        min-height: 400px;
        display: flex;
        display: -webkit-flex;
        display: -webkit-flexbox;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 50px;

        > .left {

            z-index: 1;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;

            > .content {

                width: 100%;
                margin-top: auto;

                .userName > div {
                    color: #0C3E57;
                    font-size: 50px;
                    font-weight: 600;
                    line-height: 51px;
                    overflow-wrap: break-word;
                }

                .baseline {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: flex-start;

                    > span {
                        font-size: 20px;
                        font-weight: 500;
                        cursor: pointer;
                    }

                    .progress {
                        > div {
                            width: 120px !important;
                            height: 120px !important;
                        }

                        margin-bottom: 15px;
                    }

                    :global {
                        .ant-progress-status-success .ant-progress-circle-path,
                        .ant-progress-status-success .ant-progress-text {
                            color: #29db73;
                            stroke: #29db73 !important;
                            font-size: 50px;
                        }
                    }

                }

                h3 {
                    font-size: 14px;
                    font-weight: normal;
                    color: #0C3E57;
                    margin-bottom: 30px;
                }

                .action {

                    display: flex;
                    justify-content: flex-start;

                    button {
                        color: #fff;
                        background-color: #D672A9;
                        border: unset;
                        width: 180px;
                        height: 36px;

                        > span {
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
            }

            > .logo {
                margin-top: auto;
                margin-bottom: 50px;
                width: 220px;
            }
        }

        .image {
            width: 50%;
            height: 100%;
            flex-grow: 1;
            display: flex;
            display: -webkit-flex;
            display: -webkit-box;
            display: -webkit-flexbox;
            justify-content: center;
            align-items: center;

            > img {
                max-width: 50vw;
                max-height: 220px;
                height: auto;
            }
        }
    }
}

.info {

    width: 75vw !important;

    :global {

        .ant-modal-content {
            width: 75vw;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.4);
        }

        .ant-modal-confirm-title {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 20px;
        }

        .ant-modal-confirm-content {
            font-size: 16px;
            text-align: justify;
            p {
                font-weight: 300;
            }
        }
    }

}