.dashboard {
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;

    .layout {

        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        > .left {

            z-index: 1;
            margin: 0 50px;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;

            > .content {

                width: 100%;
                margin-top: auto;

                .baseline {
                    margin-top: 50px;
                    margin-bottom: 50px;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    align-items: center;

                    > span {
                        font-size: 24px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }

                    .progress {
                        > div {
                            width: 160px !important;
                            height: 160px !important;
                        }

                        margin-top: 15px;
                        margin-right: 30px;
                    }

                    img {
                        width: 60px;
                    }

                    :global {
                        .ant-progress-status-success .ant-progress-circle-path,
                        .ant-progress-status-success .ant-progress-text {
                            color: #29db73;
                            stroke: #29db73 !important;
                            font-size: 60px;
                        }
                    }
                }

                .userName > div {
                    color: #0C3E57;
                    font-size: 95px;
                    font-weight: 600;
                    line-height: 103px;
                    overflow-wrap: break-word;
                }

                h3 {
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: normal;
                    color: #0C3E57;
                    margin-bottom: 50px;
                }

                .action {

                    button {
                        color: #fff;
                        background-color: #D672A9;
                        border: unset;
                        width: 350px;
                        height: 70px;

                        > span {
                            font-size: 24px;
                            font-weight: 500;
                        }
                    }
                }
            }

            > .logo {
                margin-top: auto;
                margin-bottom: 50px;
                width: 300px;
            }
        }

        .image {
            padding: 50px;
            background-image: url('./assets/dashboard-background.svg');
            background-repeat: no-repeat;
            background-position: left;
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > img {
                max-width: 35vw;
                max-height: 35vh;
                width: 100%;
                height: auto;
            }
        }
    }
}