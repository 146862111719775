@import "src/styles/resolutions";

.root {

    width: 100%;
    flex: 1 0 auto;

    > .header {
        display: flex;
        justify-content: space-between;
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 10px;

        > .title {
            font-size: 32px;
            font-weight: 500;
        }

        > .index {
            font-size: 18px;
            font-weight: 500;
        }
    }

    > .options {
        width: 100%;
        display: flex;
        flex-direction: column;

        > button {
            margin-bottom: 20px;
            height: 50px;
            font-size: 24px;
            color: #fff;
            opacity: 0.3;
            background-color: #D672A9;
            border: 2px solid #D672A9;
            border-radius: 36px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            > span {
                font-weight: 400;
            }

            &:hover {
                color: #fff;
                background-color: #D672A9;
                opacity: 1;
            }
        }

        > .selected {
            color: #fff;
            background-color: #D672A9;
            opacity: 1;
        }
    }
}