@import "src/styles/resolutions";

.feedback {
    background-color: #F5F8FB;
    height: 100%;
    padding: 50px;

    > .header {
        font-size: 50px;
        font-weight: 600;
    }

    > .modal {

        display: flex;
        justify-content: center;
        margin: 50px;

        > .content {
            padding: 25px;
            background-color: #fff;
            box-shadow: 0 0 12px #03172B1C;
            border-radius: 10px;

            > .header {
                font-size: 18px;
                font-weight: 600;
            }

            .rates {

                margin: 10px 0;

                > div {
                    font-size: 18px;
                    font-weight: 500;
                }

                > .rating {
                    width: 100%;
                    text-align: center;
                    @media @mobile, @tablet {
                        font-size: 48px;
                    }
                    @media @desktop {
                        font-size: 72px;
                    }
                }
            }

            .comment {

                > textarea {
                    color: #348cab;
                    font-size: 18px;
                    border-radius: 18px;
                    border: 1px #13c2c2 solid !important;
                    background-color: rgba(112, 205, 233, 0.05);
                    padding: 10px 15px;

                    &::placeholder {
                        color: #70cde980;
                    }

                    &:active, &:focus, &:visited {
                        border: 1px #70cde9 solid;
                        box-shadow: 0 0 0 2px rgba(112, 205, 233, 0.2);
                    }
                }

                .textLimit {
                    width: 100%;
                    text-align: center;
                    margin-top: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #70cde9;
                }

            }
        }

        .footer {
            margin: 20px 0 0;
            display: flex;
            justify-content: space-between;

            > button {
                border-radius: 36px;
                width: 100px;
                height: 50px;
                > span {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}