.bingo {

    height: 100vh;
    display: flex;
    background-image: url('./assets/background.svg');
    background-size: cover;
    background-repeat: no-repeat;

    > .left {
        height: 100%;
        width: 60%;
        color: #fff;
        padding: 0 50px;
        display: flex;
        flex-direction: column;

        > .header {
            padding: 30px 0 40px;
            font-size: 22px;

            > div > div {
                font-weight: 400;
            }

            > .bravo {
                font-size: 60px;
                font-weight: 500;
            }
        }

        > .scores {

            position: relative;
            display: grid;
            grid-template-columns: auto max-content;

            .scoresValues {

                margin: 0 0 25px;
                max-height: 100%;

                > svg {
                    width: 100%;
                    height: auto;
                    max-height: 100%;
                }

            }

            > .overall {

                position: relative;
                display: grid;
                grid-template-columns: auto max-content;
                grid-template-rows: minmax(100%, 300px);

                > div {

                    margin: 0 0 25px;
                    max-height: 100%;

                    > svg {
                        width: 100%;
                        height: auto;
                        max-height: 100%;
                    }
                }

            }
        }

        .finished {
            margin-top: auto;
            margin-right: 10px;
            background-color: #D672A9;
            border: unset;
            color: #fff;
            height: 50px;
            width: 300px;

            a {
                font-size: 18px;
                font-weight: 500;
                padding: 0 10px;
            }

            &:hover {
                opacity: 0.5;
            }
        }

        .logo {
            margin-top: 35px;
            margin-bottom: 50px;
            width: 150px;
        }
    }

    > .right {
        width: 40%;
        height: 100%;
        padding: 50px;

        > div {

            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            > img {
                width: auto;
                height: 100%;
            }
        }
    }
}