@import "src/styles/resolutions";

.app {
    display: flex;
    display: -webkit-flex;
    display: -webkit-flexbox;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background-color: #F6F8FB;
    width: 100%;
    height: 100%;
}