.cls-1, .cls-3, .cls-4 {
    fill: #fff;
}

.cls-2 {
    color: #0f3f56;
    fill: #0f3f56;
    width: 100%;
    height: 100%;
    font-size: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cls-2 > tspan, .cls-4 > tspan {
    font-family: Poppins-SemiBold, Poppins, serif;
    font-weight: 600;
}

.cls-3 {
    font-size: 14.4px;
    font-family: Poppins-Regular, Poppins, serif;
}

.cls-4 {
    text-align: center;
    font-size: 24px;
}