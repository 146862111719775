.dashboard {
    background-image: url('../../dashboard/assets/dashboard-background.svg');
    background-repeat: no-repeat;
    background-position: calc(75vw / 2) calc(-50vh / 2);
    background-color: #fff;
    display: flex;
    display: -webkit-flex;
    display: -webkit-flexbox;
    justify-content: center;
    min-height: 100%;

    .layout {

        width: 100%;
        min-height: 400px;
        display: flex;
        display: -webkit-flex;
        display: -webkit-flexbox;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 50px;

        > .left {

            z-index: 1;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;

            > .content {

                width: 100%;
                margin-top: auto;

                h1 {
                    font-size: 60px;
                    line-height: 60px;
                    font-weight: 600;
                    margin: 25px 0;
                }

                h2 {
                    font-size: 24px;
                    margin: 25px 0;
                }

                .action {

                    display: flex;
                    justify-content: flex-start;

                    button {
                        color: #fff;
                        background-color: #D672A9;
                        border-radius: 36px;
                        border: unset;
                        width: 300px;
                        height: 50px;

                        > span {
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }
                }
            }

            > .logo {
                margin-top: auto;
                margin-bottom: 50px;
                width: 220px;
            }
        }

        .image {
            width: 50%;
            height: 100%;
            flex-grow: 1;
            display: flex;
            display: -webkit-flex;
            display: -webkit-box;
            display: -webkit-flexbox;
            justify-content: center;
            align-items: center;

            > img {
                max-width: 50vw;
                max-height: 220px;
                height: auto;
            }
        }

        .exit {
            position: fixed;
            right: 35px;
            top: 35px;

            > button {
                width: 100px;
                height: 50px;
                border: unset;
                border-radius: 36px;
                background-color: #fff;

                a {
                    color: #000;
                    font-size: 18px;
                    font-weight: 600;
                }

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}
